import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

const Header = () => (
  <StaticQuery
    query={graphql`
      {
        image: file(name: { eq: "header" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
    `}
    render={(data) => {
      const image = getImage(data.image);
      return (
        <HeaderWrapper id="home">
          <Head>
            <GatsbyImage image={image} alt="header" />
          </Head>
        </HeaderWrapper>
      );
    }}
  ></StaticQuery>
);

const Head = styled.div`
  display: flex;
  padding-top: 13vh;
  height: 74vh;
  @media (min-width: ${(props) => props.theme.screen.xs}) {
    height: 100vh;
    padding: 0;
  }
`;

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.navy};
  padding-top: 50px;
  color: ${(props) => props.theme.color.pink};
  min-height: 100vh;
`;

export default Header;
