export const recommendations = {
  S1: [
    {
      name: "Steam Yard",
      image: "steamYard.jpg",
      category: "Eat",
      url: "http://steamyard.co.uk/",
      description: "Great coffee, sandwiches and baked goods",
    },
    {
      name: "National Videogame Museum",
      image: "nvm.jpg",
      category: "Do",
      url: "https://thenvm.org/",
      description: "Unique museum all about video games",
    },
    {
      name: "Kommune",
      image: "kommune.jpg",
      category: "Eat / Drink",
      url: "http://kommune.co.uk/",
      description: "Foodhall with great options for brunch, lunch and dinner",
    },
    {
      name: "Triple Point",
      image: "twistedBurger.jpg",
      category: "Eat / Drink",
      url: "https://triplepointbrewing.co.uk/",
      description: "Great burgers with beers brewed on-site",
    },
    {
      name: "Napoli Centro",
      image: "napoliCentro.jpg",
      category: "Eat",
      url: "http://napolicentro.co.uk/",
      description: "Delicious Neapolitan style pizza",
    },
    {
      name: "Sellers Wheel",
      image: "sellersWheel.jpg",
      category: "Eat",
      url: "https://tampercoffee.co.uk/kiwi-cafe-culture/locations-2/",
      description: "Amazing Brunches",
    },
    {
      name: "Street Food Chef",
      image: "streetFood.jpg",
      category: "Eat",
      url: "https://streetfoodchef.co.uk/",
      description: "Burritos!",
    },
    {
      name: "Weston Park Museum",
      image: "weston.jpg",
      category: "Do",
      url: "https://www.museums-sheffield.org.uk/museums/weston-park/home",
      description:
        "Sheffield’s largest museum housed in a grade II listed building",
    },
    {
      name: "Moor Market",
      image: "moor.jpg",
      category: "Shop",
      url: "https://sheffieldmarkets.com/markets/moor-market",
      description: "Modern, indoor farmers' market",
    },
    {
      name: "Sidney & Matilda",
      image: "sm.jpg",
      category: "Do",
      url: "www.sidneyandmatilda.com",
      description: "Multi-disciplinary arts project space & social club",
    },
    {
      name: "Winter Garden",
      image: "winterG.jpg",
      category: "Do",
      url:
        "https://www.sheffield.gov.uk/home/parks-sport-recreation/public-spaces/winter-garden.html",
      description:
        "Largest urban glasshouse in Europe, home to more than 2000 plants",
    },
    {
      name: "Lane 7",
      image: "lane.jpg",
      category: "DO",
      url: "https://lane7.co.uk/",
      description: "Eat, drink and bowl",
    },
    //TODO
    // Vintage Shops
    {
      name: "Mount Lebanon",
      image: "mount.jpg",
      category: "Eat",
      url: "https://mountlebanon.co.uk",
      description: "Purveyor of fine Lebanese food",
    },
    {
      name: "QC's Bagel Bar",
      image: "qc.jpg",
      category: "Eat",
      url: "https://www.facebook.com/QCsBagelBar/",
      description: "Delicious bagels and wraps",
      holly: "The Cathedral",
      Josh: "The Mumbai",
    },
    {
      name: "Moonko",
      image: "moonko.jpg",
      category: "Shop",
      url: "https://moonko.co.uk/",
      description: "Little independent shop for all things botanical",
    },
    {
      name: "Bungalows & Bears",
      image: "bung.jpg",
      category: "Eat / Drink",
      url: "https://www.bungalowsandbears.com/",
      description: "Good bar to start a Sheffield night out",
    },
    {
      name: "Humpit",
      image: "humpit.jpg",
      category: "Eat",
      url: "https://humpit-hummus.com/",
      description: "Hummus and pitta bar",
    },
  ],
  S3: [
    {
      name: "Cutlery Works",
      image: "cutleryWorks.jpg",
      category: "Eat",
      url: "https://cutleryworks.co.uk/",
      description: "Two floors of delicious street food and good beer",
    },
    {
      name: "Fat Cat",
      image: "fatcat.jpg",
      category: "Drink",
      url: "https://www.thefatcat.co.uk/",
      description: "Sheffield real ale institution",
    },
    {
      name: "Church",
      image: "church.jpg",
      category: "Eat / Drink",
      url: "https://templeof.fun/",
      description: "Unique bar with great vegan food",
    },
    {
      name: "Kelham Flea",
      image: "flea.png",
      category: "Shop",
      url: "https://the-kelham-flea.business.site/",
      description: "Antiques and vintage centre",
    },
    {
      name: "Kelham Island Museum",
      image: "kel.jpg",
      category: "Do",
      url: "http://www.simt.co.uk/kelham-island-museum",
      description: "Explore Sheffield's industrial heritage",
    },
    {
      name: "Peddler Market",
      image: "peddler.jpg",
      category: "Eat / Drink",
      url: "https://peddlermarket.co.uk/",
      description: "Monthly street food event",
    },
    {
      name: "Nether Edge Pizza",
      image: "netheredge.jpg",
      category: "Eat",
      url: "https://www.netheredgepizza.com/pizzerias/kelham-island/",
      description: "Sheffield's finest wood fired pizzas",
    },
    //MORE PUBS - oustide bar -island & ...
  ],
  S7: [
    {
      name: "Nether Edge Pizza",
      image: "netheredge.jpg",
      category: "Eat",
      url: "https://www.netheredgepizza.com/pizzerias/abbeydale-road/",
      description: "Sheffield's finest wood fired pizzas",
    },
    {
      name: "Barrow Boy",
      image: "deckards.jpg",
      category: "Eat / Drink",
      url: "https://www.facebook.com/barrowboybar/",
      description: "Cocktails & Baos",
    },
    {
      name: "Forge Bakehouse",
      image: "forge.jpg",
      category: "Eat",
      url: "https://forgebakehouse.co.uk/",
      description: "Homemade breads, pastries and baked goods",
    },
    {
      name: "The Broadfield",
      image: "broadfield.jpg",
      category: "Eat / Drink",
      url: "https://www.thebroadfield.co.uk/",
      description:
        "Rustic styled pub with great sunday lunch and extensive whiskey selection",
    },
    {
      name: "Picturehouse Social",
      image: "ph.jpg",
      category: "Eat / Drink",
      url: "https://picture-house-social.co.uk/",
      description: "Pizzas and beers at the historic Abbeydale Picturehouse",
    },
    {
      name: "Urban-Ita",
      image: "urban.jpg",
      category: "Eat",
      url: "https://www.facebook.com/urbanita288/",
      description: "Authentic Italian eatery",
    },
    {
      name: "Bragazzis",
      image: "bragg.jpg",
      category: "Eat",
      url: "https://www.facebook.com/bragazzis/",
      description: "Italian coffee shop & deli",
    },
    {
      name: "The Teller",
      image: "teller.jpg",
      category: "Eat / Drink",
      url: "https://www.facebook.com/teller.sheffield/",
      description: "Great beers and tasty burgers from Slap and Pickle",
    },
    {
      name: "Meersbrook Park",
      image: "meersb.jpg",
      category: "DO",
      url: "http://www.meersbrookpark.co.uk/",
      description: "Great park with panoramic views of the city",
    },
    {
      name: "Gravel Pit",
      image: "gp.jpg",
      category: "Shop",
      url: "https://gravelpitshop.com/",
      description:
        "Local Abbeydale business specialising in native and tropical house plants",
    },
    {
      name: "Cole's Corner",
      image: "cc.jpg",
      category: "Eat / Shop",
      url: "https://colescorner.co.uk/",
      description: "Independent cafe bar and gift shop",
    },
    {
      name: "Fanoush",
      image: "fanoush.jpg",
      category: "Eat",
      url: "https://www.facebook.com/fanoushfalafel/",
      description: "Best falafel wrap in town",
    },
    {
      name: "Two Thirds Beer Co.",
      image: "twot.jpg",
      category: "DRINK",
      url: "https://twothirdsbeer.co/",
      description: "Neighbourhood craft beer bar",
    },
  ],
  S11: [
    ///TODO SHOPS
    {
      name: "Năm Sông",
      image: "ns.jpg",
      category: "Eat",
      url: "https://www.namsong.co.uk/",
      description: "Voted Sheffield's Best South East Asian Restaurant",
    },
    {
      name: "Eve Kitchen",
      image: "eve.jpg",
      category: "Eat",
      url: "http://www.evekitchen.co.uk/",
      description: "Handmade doughnuts",
    },
    {
      name: "Porter Pizza Company",
      image: "pp.jpg",
      category: "Eat",
      url: "https://www.porterpizza.co.uk/",
      description: "Authentic neapolitan style pizza",
    },
    {
      name: "Botanical Gardens",
      image: "bot.jpg",
      category: "Do",
      url: "http://www.sbg.org.uk/",
      description: "Beautiful gardens perfect for a summer walk",
    },
    {
      name: "Two Steps",
      image: "ts.jpg",
      category: "Eat",
      url: "https://www.facebook.com/TwoStepsFishAndChipShop",
      description: "Sheffield's best fish and chips",
    },
    {
      name: "Greedy Greek",
      image: "gg.jpg",
      category: "Eat",
      url: "http://thegreedygreekdeli.co.uk/",
      description: "Family run Greek eatery",
    },
    {
      name: "The Lescar",
      image: "lescar.jpg",
      category: "Drink",
      url: "https://www.thelescarhuntersbar.co.uk/",
      description:
        "Old fashioned pub serving beer, seasonal food and live jazz",
    },
    {
      name: "Street Food Chef",
      image: "sf.jpg",
      category: "Eat",
      url: "https://streetfoodchef.co.uk/",
      description: "Burritos!",
    },
  ],
};
