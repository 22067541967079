import styled from 'styled-components';
import React from 'react';

export const SmallButton = ({ children, onClick, ...rest }) => (
  <StyledButton onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

const StyledButton = styled.button`
  color: ${props => props.theme.color.navy};
`;
