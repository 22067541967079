import React, { useState } from "react";
import { Row, Col, FormControl, FormGroup, FormCheck } from "react-bootstrap";
import styled from "styled-components";
import { SmallButton } from "./Button";
import { Formik } from "formik";
import axios from "axios";

function FullForm({ onClose, submissionStatus, setStatus }) {
  const blankAttendee = { name: "", attending: "", meal: "" };
  const [attendees, setAttendees] = useState([{ ...blankAttendee }]);
  const addAttendee = (event) => {
    event.preventDefault();
    setAttendees([...attendees, { ...blankAttendee }]);
  };

  const [showErrors, setShowErrors] = useState(false);

  const removeAttendee = (idx) => {
    const updatedAttendees = [...attendees];
    updatedAttendees.splice(idx, 1);
    setAttendees(updatedAttendees);
  };

  const handleAttendeeChange = (e) => {
    const updatedAttendees = [...attendees];
    const name = e.target.name.split("-")[0];
    updatedAttendees[e.target.dataset.idx][name] = e.target.value;
    setAttendees(updatedAttendees);
  };

  const handleResponse = (success) => {
    success
      ? setStatus({ submitting: false, success: true, error: false })
      : setStatus({ submitting: false, success: false, error: true });
  };

  const handleSubmit = (formValues) => {
    setStatus({ error: false, success: true, submitting: true });
    const form = { ...formValues, attendees };
    axios({
      method: "post",
      url: "https://getform.io/f/5bbc7592-175b-4cf8-8e2f-f7e1ac48d386",
      data: form,
    })
      .then((r) => {
        handleResponse(true);
      })
      .catch((r) => {
        handleResponse(false);
      });
  };

  const handleClose = () => {
    setStatus({
      submitting: false,
      error: false,
      success: false,
    });
    onClose();
  };

  if (submissionStatus.success) {
    return (
      <Success>
        <h2>Thank you!</h2>
        <p>We will keep all guests updated via email.</p>
        <p>
          If you have any questions you can contact us at the bottom of the page
        </p>
        <RsvpButton onClick={handleClose}>Close</RsvpButton>
      </Success>
    );
  }

  if (submissionStatus.error) {
    return (
      <Success>
        <h2>Oh no!</h2>
        <p>
          Unfortunately your form did not submit successfully. Please Try Again.
        </p>
        <RsvpButton
          onClick={() =>
            setStatus({
              submitting: false,
              error: false,
              success: false,
            })
          }
        >
          Try Again
        </RsvpButton>
      </Success>
    );
  }

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Please provide an email";
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.phone) {
      errors.phone = "Please provide a phone number";
    }

    if (attendees.length < 1) {
      errors.attendees = "Please add a guest";
    }

    attendees.forEach((attendee, idx) => {
      if (!attendee.name) {
        errors[`${idx}-name`] = "Please provide guest name";
      }
      if (attendee.attending === "") {
        errors[`${idx}-attending`] = "Please select an option";
      }
      console.log(attendee.attending);
      if (attendee.attending === "YES" && !attendee.meal) {
        errors[`${idx}-meal`] = "Please select an option";
      }
    });
    console.log(errors);
    return errors;
  };

  return (
    <Container>
      <Formik
        initialValues={{
          email: "",
          phone: "",
          additional: "",
        }}
        onSubmit={(formValues) => handleSubmit(formValues)}
        validate={validate}
        validateOnChange
      >
        {({ errors, handleChange, handleSubmit, values, touched }) => {
          return (
            <>
              <h4>Contact Details</h4>
              <Row>
                <FormGroup as={Col}>
                  <FormControl
                    style={{ borderRadius: "0.75rem" }}
                    name="email"
                    value={values.email}
                    type="email"
                    required
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  {errors.email && touched.email && (
                    <Error>{errors.email}</Error>
                  )}
                </FormGroup>
                <FormGroup as={Col} controlId="formGridTelephone">
                  <FormControl
                    style={{ borderRadius: "0.75rem" }}
                    name="phone"
                    value={values.phone}
                    type="text"
                    required
                    placeholder="Phone"
                    onChange={handleChange}
                  />
                  {errors.phone && touched.phone && (
                    <Error>{errors.phone}</Error>
                  )}
                </FormGroup>
              </Row>
              <Head>
                <h4>Guests</h4>
                <SmallButton onClick={addAttendee}>+ Guest</SmallButton>
              </Head>

              {attendees.map((val, idx) => {
                const nameId = `name-${idx}`;
                const attendingId = `attending-${idx}`;
                const mealId = `meal-${idx}`;

                return (
                  <Guest key={`attendee-${idx}`}>
                    {errors.attendees && <Error>{errors.attendees}</Error>}
                    <GuestHead>
                      <GTitle>Guest {idx + 1}</GTitle>
                      {idx !== 0 && (
                        <SmallButton onClick={() => removeAttendee(idx)}>
                          - Guest
                        </SmallButton>
                      )}
                    </GuestHead>
                    <Row>
                      <FormGroup as={Col} controlId="formGridEmail">
                        <FormControl
                          style={{ borderRadius: "0.75rem" }}
                          value={attendees[idx].name}
                          data-idx={idx}
                          name={nameId}
                          required
                          type="text"
                          placeholder="Name"
                          onChange={handleAttendeeChange}
                        />
                        {errors[`${idx}-name`] && showErrors && (
                          <Error>{errors[`${idx}-name`]}</Error>
                        )}
                      </FormGroup>
                      <FormGroup as={Col} controlId="formGridAttending">
                        <FormCheck
                          name={attendingId}
                          data-idx={idx}
                          label="Count me in!"
                          value="Yes"
                          type="radio"
                          required
                          onChange={handleAttendeeChange}
                        />
                        <FormCheck
                          name={attendingId}
                          data-idx={idx}
                          label="I cannot make it"
                          value="No"
                          type="radio"
                          onChange={handleAttendeeChange}
                        />
                        {errors[`${idx}-attending`] && showErrors && (
                          <Error>{errors[`${idx}-attending`]}</Error>
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      {attendees[idx].attending === "Yes" && (
                        <FormGroup as={Col} controlId="formGridState">
                          <FormControl
                            style={{ borderRadius: "0.75rem" }}
                            name={mealId}
                            data-idx={idx}
                            className="meal"
                            as="select"
                            required={attendees[idx].attending === "Yes"}
                            onChange={handleAttendeeChange}
                          >
                            <option value="">Dietary preference</option>
                            <option value="meat">Meat</option>
                            <option value="vegetarian">Vegetarian</option>
                            <option value="vegan">Vegan</option>
                          </FormControl>
                          {errors[`${idx}-meal`] && showErrors && (
                            <Error>{errors[`${idx}-meal`]}</Error>
                          )}
                        </FormGroup>
                      )}
                    </Row>
                  </Guest>
                );
              })}

              <Row style={{ marginTop: "10px" }}>
                <FormGroup as={Col} controlId="formGridAdditional">
                  <FormControl
                    style={{ borderRadius: "0.75rem" }}
                    as="textarea"
                    rows="3"
                    name="additional"
                    value={values.additional}
                    type="text"
                    placeholder="Any further info you'd like to tell us, allergies, song requests..."
                    onChange={handleChange}
                  />
                </FormGroup>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                }}
              >
                <RsvpButton
                  disabled={submissionStatus.submitting}
                  type="submit"
                  onClick={() => {
                    setShowErrors(true);
                    handleSubmit();
                  }}
                >
                  Submit
                </RsvpButton>
              </div>
            </>
          );
        }}
      </Formik>
    </Container>
  );
}

export default FullForm;

const Guest = styled.div`
  padding: 0px 20px 0px 20px;
  font-family: ${(props) => props.theme.font.secondary};
`;

const GTitle = styled.div`
  font-size: 16px;
  font-family: ${(props) => props.theme.font.secondary};
  text-transform: uppercase;
`;

const Container = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  color: ${(props) => props.theme.color.terracotta};
  h4 {
    font-weight: bold;
    color: ${(props) => props.theme.color.terracotta};
  }
`;

const RsvpButton = styled.button`
  border: 5px ${(props) => props.theme.color.terracotta} solid;
  font-family: ${(props) => props.theme.font.primary};
  outline: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 300;
  color: ${(props) => props.theme.color.terracotta};
  width: 200px;
`;

const GuestHead = styled.div`
  display flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom:10px;
`;

const Head = styled(GuestHead)`
  margin-right: 20px;
`;

const Error = styled.p`
  color: ${(props) => props.theme.color.terracotta};
  font-style: italic;
  font-size: 16px;
  margin-bottom: 0px;
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-family: ${(props) => props.theme.font.secondary};
    color: ${(props) => props.theme.color.navy};
    font-weight: normal;
    padding-bottom: 20px;
  }
  p {
    font-family: ${(props) => props.theme.font.secondary};
    font-size: 20px;
    padding: 0px 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  button {
    margin: 20px;
  }
`;
