const theme = {
  font: {
    primary: `'Abril Fatface'`,
    secondary: `'Raleway'`,
  },
  font_size: {
    small: "font-size: 20px; line-height: 30px",
    regular: "font-size: 24px; line-height: 32px",
    large: "font-size: 30px; line-height: 40px",
    larger: "font-size: 36px; line-height: 48px",
    xlarge: "font-size: 48px; line-height: 56px",
    header: "font-size: 180px",
  },
  color: {
    navy: "#1B3335",
    terracotta: "#B66049",
    pink: "#F2C2B3",
    blue: "#677979",
    green: "#89A28D",
    peach: "#D7AA83",
    gold: "#A97C41",
    grey: "#CDC9C6",
    dgreen: "#374A37",
    turq: "#488075",
    dturq: "#264C41",
    lpink: "#FEE6DC",
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
    xl: "1599px",
  },
};

export default theme;
