import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    padding: 0 40px;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    padding: 0 144px;
  }

  @media (min-width: ${(props) => props.theme.screen.xl}) {
    padding: 0 200px;
  }
`;

export const Section = styled.section`
  padding: 70px 0;
  overflow: hidden;
  min-height: 100vh;

  @media (min-width: ${(props) => props.theme.screen.lg}) {
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    padding: 30px 0;
  }

  ${(props) =>
    props.accent &&
    `background-color: ${
      props.accent === "secondary"
        ? props.theme.color.lpink
        : props.theme.color.navy
    }`};
`;
