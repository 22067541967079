import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { recommendations } from "./recomendationList";
import ItemsCarousel from "react-items-carousel";
import styled from "styled-components";
import useWindowSize from "../../utils/getWindowSize";
import { StaticQuery, graphql } from "gatsby";

function Recommendations({ location }) {
  const [activeItemIndex, setActiveItemIndex] = useState({
    S1: 0,
    S3: 0,
    S7: 0,
    S11: 0,
  });

  const [cardsToShow, setCardsToShow] = useState(1);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 575) {
      setCardsToShow(1);
    }
    if (width > 575) {
      setCardsToShow(2);
    }
    if (width > 767) {
      setCardsToShow(3);
    }
    if (width > 991) {
      setCardsToShow(4);
    }
    // if (width > 1080) setCardsToShow(4);
  });

  const handleUpdate = (index) => {
    setActiveItemIndex({
      ...activeItemIndex,
      [location]: index,
    });
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { sourceInstanceName: { eq: "sheffield" } }) {
            edges {
              node {
                id
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    aspectRatio: 1
                    transformOptions: { fit: COVER }
                  )
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = data.allFile.edges.reduce((imgs, { node }) => {
          const img = getImage(node);
          const src = getSrc(node);
          const key = /[^/]*$/.exec(src)[0];
          imgs[key] = img;
          return imgs;
        }, {});

        return (
          <ItemsCarousel
            infiniteLoop={true}
            gutter={10}
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons={true}
            numberOfCards={cardsToShow}
            slidesToScroll={cardsToShow}
            outsideChevron={true}
            activeItemIndex={activeItemIndex[location]}
            requestToChangeActive={handleUpdate}
            rightChevron={">"}
            leftChevron={"<"}
          >
            {recommendations[location].map(
              ({ name, image, url, category, description }) => {
                return (
                  <a
                    style={{ textDecoration: "none" }}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Card key={name}>
                      <GatsbyImage image={images[image]} alt={name} />
                      <div>
                        <h5>{category}</h5>
                        <h3>{name}</h3>
                        <h6> {description}</h6>
                      </div>
                    </Card>
                  </a>
                );
              }
            )}
          </ItemsCarousel>
        );
      }}
    ></StaticQuery>
  );
}

export default Recommendations;

export const Title = styled.p`
  color: ${(props) => props.theme.color.navy};
`;

export const Subtitle = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.navy};
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.color.pink};
  color: ${(props) => props.theme.color.navy};
  font-family: ${(props) => props.theme.font.secondary};
  height: 100%;
  h3 {
    color: ${(props) => props.theme.color.terracotta};
    font-family: ${(props) => props.theme.font.primary};
    font-size: 1.5rem;
  }

  h5 {
    text-transform: uppercase;
  }

  div {
    padding: 10px;
  }
`;
