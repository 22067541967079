import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import { Container } from "@components/global";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const NAV_ITEMS = [
  "Home",
  "The Day",
  "RSVP",
  "Location",
  "Sheffield",
  "Gifts",
  "Important Info",
  "Contact Us",
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };
  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };
  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };
  getNavAnchorLink = (item) => (
    <AnchorLink
      href={`#${item === "RSVP" ? "the day" : item.toLowerCase()}`}
      onClick={this.closeMobileMenu}
    >
      {item}
    </AnchorLink>
  );
  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map((navItem) => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );
  render() {
    const { mobileMenuOpen } = this.state;
    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: "White" }}>
              <StaticImage alt="menu icon" src="../../static/menu.svg" />
            </button>
          </Mobile>
          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;

export const Nav = styled.nav`
  padding: 16px 0;
  background-color: ${(props) => props.theme.color.navy};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    text-align: center;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};

  a {
    text-decoration: none;
    text-weight: bold;
    color: ${(props) => props.theme.color.pink};
    font-size: 18px;
    text-transform: uppercase;

    :hover {
      text-decoration: none;
      color: ${(props) => props.theme.color.terracotta};
    }
  }

  &.active {
    a {
      text-decoration: underline;
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.navy};
`;

export const Brand = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
  }

  ${(props) =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;
