import React, { useState } from "react";
import styled from "styled-components";
import "./RSVP.css";
import Modal from "react-bootstrap/Modal";
import FullForm from "@components/Form";
import { Section } from "@components/global";

function TheDay() {
  const [show, setShow] = useState(false);

  const [submissionStatus, setStatus] = useState({
    submitting: false,
    success: false,
    error: false,
  });

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setStatus({ submitting: false, success: false, error: false });
    setShow(true);
  };

  return (
    <StyledSection id="the day">
      <Timeline>
        <h4>
          <span>11am</span>Arrival
        </h4>

        <Dot />
        <h4>
          <span>11.30am</span>Ceremony
        </h4>
        <p>The main event</p>
        <Dot />
        <h4>
          <span>12.15pm</span>Drinks
        </h4>
        <p>Celebratory champagne in the park</p>
        <Dot />
        <h4>
          <span>1.30pm</span>Reception Begins
        </h4>
        <p>Drinks at S+M</p>
        <Dot />
        <h4>
          <span>3pm</span>Dinner
        </h4>
        <p>Sit down meal & speeches</p>
        <Dot />
        <h4>
          <span>7pm</span>Party
        </h4>
        <p>Let's dance</p>
        <Dot />
        <h4>
          <span>9pm</span>Pizza
        </h4>
        <p>Party Fuel</p>
        <Dot />
        <h4>
          <span>2am</span>Hometime
        </h4>
      </Timeline>
      <RSVP>
        <Date>03.07.21</Date>
        <Para>We can't wait to celebrate with you!</Para>

        <Para>
          Please let us know if you can make it by 17th May at the latest
        </Para>

        <RsvpButton onClick={handleShow}>RSVP</RsvpButton>
      </RSVP>
      <Modal
        dialogClassName={"hello"}
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Body>
          <Head>
            <h4>RSVP</h4>
            <Close onClick={handleClose}>x</Close>
          </Head>
          <FullForm
            onClose={handleClose}
            submissionStatus={submissionStatus}
            setStatus={setStatus}
          />
        </Modal.Body>
      </Modal>
    </StyledSection>
  );
}

const Date = styled.h2`
  margin-bottom: 30px;
  color: ${(props) => props.theme.color.terracotta};
  font-size: 4.25rem;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 70px;
  text-align: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 0px 10px;
  }

  h4 {
    font-family: ${(props) => props.theme.font.secondary};
    color: ${(props) => props.theme.color.navy};
    margin-bottom: 0px;
  }

  span {
    margin-right: 10px;
    font-size: 32px;
    font-family: ${(props) => props.theme.font.primary};
    color: ${(props) => props.theme.color.pink};
  }

  p {
    font-size: 18px;
    margin-bottom: 0px;
    font-family: ${(props) => props.theme.font.secondary};
  }
`;

const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.theme.color.terracotta};
  border-radius: 50%;
  display: inline-block;
  margin: 10px 0px 8px 0px;
`;

const Para = styled.p`
  font-family: ${(props) => props.theme.font.secondary};
  margin-bottom: 40px;
  font-size: 32px;
  color: ${(props) => props.theme.color.terracotta};
  font-weight: 600px;
`;

const RsvpButton = styled.button`
  border: 5px ${(props) => props.theme.color.terracotta} solid;
  font-family: ${(props) => props.theme.font.primary};
  outline: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 300;
  color: ${(props) => props.theme.color.terracotta};
  width: 100px;
`;

const RSVP = styled.div`
  justify-content: center;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 50px;
  background-color: ${(props) => props.theme.color.pink};

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    min-height: 100vh;
  }
`;

const StyledSection = styled(Section)`
  display: flex;
  padding: 0px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`;

const Close = styled.button`
  color: ${(props) => props.theme.color.terracotta};
  font-weight: 600;
  font-size: 16px;
  outline: none;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  h4 {
    font-size: 32px;
    color: ${(props) => props.theme.color.terracotta};
    font-family: ${(props) => props.theme.font.primary};
  }
`;
export default TheDay;
