import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Section, Container } from "@components/global";
import ExternalLink from "@components/ExternalLink";
import { StaticQuery, graphql } from "gatsby";

const Location = () => (
  <StaticQuery
    query={graphql`
      {
        image: file(name: { eq: "map" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
    `}
    render={(data) => (
      <Section id="location" accent>
        <Container>
          <h2 style={{ marginTop: 40, marginBottom: 30 }}>Location</h2>
          <InnerContainer>
            <Map>
              <ExternalLink href="https://www.google.com/maps/d/u/0/viewer?mid=1ICbxwS5E6vqYCXEQ0HZV2-YRwM4rZIrQ&ll=53.37872108591901%2C-1.4813391272603837&z=14">
                <GatsbyImage image={getImage(data.image)} alt="map" />
              </ExternalLink>
            </Map>
            <Locations>
              <Venue>
                <h5>Ceremony</h5>
                <ExternalLink href="https://www.sheffield.gov.uk/home/parks-sport-recreation/parks-green-spaces">
                  Weston Park Bandstand
                </ExternalLink>
                <p>Western Bank, Sheffield, S10 2TP</p>
              </Venue>

              <Venue>
                <h5>Reception</h5>
                <ExternalLink href="https://www.sidneyandmatilda.com/">
                  Sidney & Matilda
                </ExternalLink>
                <p>Rivelin Works, Sidney St, Sheffield, S1 4RH</p>
              </Venue>
            </Locations>
          </InnerContainer>
          <Directions>
            There is a 23 min walk between the ceremony and reception venue. We
            recommend{" "}
            <ExternalLink href="https://citytaxis.com/">
              Sheffield City Taxis
            </ExternalLink>{" "}
            (0114 239 39 39) or Uber.
          </Directions>
          <Directions>
            The are a number of car parks situated near both venues, the
            locations of these alongside the nearest hotels & our Sheffield
            recommendations can be viewed via the map above.
          </Directions>
        </Container>
      </Section>
    )}
  ></StaticQuery>
);

export default Location;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (min-width: ${(props) => props.theme.screen.lg}) {
    flex-direction: row;
  }
`;

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => props.theme.color.pink};

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: row;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    flex-direction: column;
    margin-left: 20px;
    justify-content: flex-start;

    h5 {
      font-size: 16px;
    }
  }
`;

const Map = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 20px;

  :hover {
    opacity: 80%;
  }
`;

const Venue = styled.div`
  min-width: 220px;
  p {
    color: ${(props) => props.theme.color.pink};
    font-family: ${(props) => props.theme.font.secondary};
    font-size: 24px;
    margin-bottom: 30px;
    @media (min-width: ${(props) => props.theme.screen.lg}) {
      font-size: 1.2rem;
    }
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.color.terracotta};

    font-size: 24px;
    font-family: ${(props) => props.theme.font.secondary};
    @media (min-width: ${(props) => props.theme.screen.lg}) {
      font-size: 1.2rem;
    }

    :hover {
      color: ${(props) => props.theme.color.terracotta};
      text-decoration: underline;
    }
  }
`;

const Directions = styled.p`
  color: ${(props) => props.theme.color.terracotta};
  margin-bottom: 10px;
  font-size: 20px;
`;
