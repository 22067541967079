import React from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";

const FaqItem = ({ title, children }) => (
  <Wrapper>
    <Collapsible
      className="faq"
      openedClassName="faq active"
      triggerClassName="faq-title"
      triggerOpenedClassName="faq-title active"
      triggerTagName="button"
      contentInnerClassName="faq-content"
      trigger={title}
      transitionTime={300}
      easing="ease-out"
    >
      {children}
    </Collapsible>
  </Wrapper>
);

export default FaqItem;

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }

  .faq {
    font-family: ${(props) => props.theme.font.secondary};
    border-bottom: 1px solid ${(props) => props.theme.color.pink};
  }

  .faq-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    color: ${(props) => props.theme.color.turq};
    font-family: ${(props) => props.theme.font.secondary};
    font-size: 20px;
    padding: 24px;
    padding-right: 72px;
    padding-left: 16px;
    position: relative;
    letter-spacing: 1px;
    font-weight: bolder;

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid ${(props) => props.theme.color.pink};
      border-bottom: 2px solid ${(props) => props.theme.color.pink};
      position: absolute;
      top: 34px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }

    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }

  .faq-content {
    padding: 0 72px 32px 16px;
    line-height: 26px;
    color: ${(props) => props.theme.color.navy};
    font-size: 20px;

    @media (max-width: ${(props) => props.theme.screen.xs}) {
      padding: 0 32px 32px 16px;
    }

    p {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
`;
