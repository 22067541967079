import React, { Component, useState } from "react";
import styled from "styled-components";
import MobileLoc from "@components/MobileLoc";

import { Section, Container } from "@components/global";
import Recommendations from "@components/Recommendations/Recommendations";

const locationLabels = [
  { location: "S1", label: "City Centre" },
  { location: "S3", label: "Kelham Island" },
  { location: "S7", label: "Nether Edge" },
  { location: "S11", label: "Sharrowvale & Ecclesall Road" },
];

function Sheffield() {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("S1");

  const handleTriggerClick = () => {
    setOpen(!open);
  };

  return (
    <Section id="sheffield">
      <Container>
        <Head> Sheffield Recommendations </Head>
        <MobileLoc
          title={locationLabels.find((x) => x.location === view).label}
          open={open}
          handleTriggerClick={setOpen}
        >
          {locationLabels.map((x, i) => (
            <div>
              <h4>
                <a
                  onClick={() => {
                    setView(x.location);
                    handleTriggerClick();
                  }}
                >
                  {x.label}
                </a>
              </h4>
            </div>
          ))}
        </MobileLoc>
        <RecommendationSection>
          <Recommendations location={view} />
        </RecommendationSection>
      </Container>
    </Section>
  );
}

export default Sheffield;

const Head = styled.h2`
  color: ${(props) => props.theme.color.navy};
  margin: 40px 0px;
  @media (max-width: 315px) {
    font-size: 1.6rem;
  }
`;

const RecommendationSection = styled.div`
  margin-top: 40px;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    padding: 0px 10px;
  }
`;
