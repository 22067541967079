import React from "react";
import styled from "styled-components";
import { Container } from "@components/global";
import ExternalLink from "@components/ExternalLink";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => (
  <React.Fragment>
    <FooterWrapper id="contact us">
      <StyledContainer>
        <Contact>
          <h2>Contact Us</h2>
          <span>
            Please feel free to contact us if you any further questions at{" "}
            <ExternalLink href="mailto:wedding.jhol@gmail.com">
              wedding.jhol@gmail.com
            </ExternalLink>
          </span>
        </Contact>
        <SocialIcons>
          <ExternalLink
            href={"https://www.instagram.com/explore/tags/jholwedding/"}
          >
            <StaticImage
              alt="instagram icon"
              src="../../static/instagram.svg"
              layout="fixed"
              width={40}
              height={40}
            />
          </ExternalLink>
        </SocialIcons>
      </StyledContainer>
    </FooterWrapper>
  </React.Fragment>
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 40px;
    height: 40px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.navy};
  padding: 32px 0;
  color: ${(props) => props.theme.color.pink};
`;

const Contact = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  padding-right: 20px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding-right: 0;x;
  }

  a {
    text-decoration: none;
    color: inherit;

    :hover {
      text-decoration: none;
      color: ${(props) => props.theme.color.terracotta};
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
