import React from "react";
import styled from "styled-components";

const ExternalLink = ({ href, children, ...other }) => (
  <StyledLink href={href} {...other} rel="noreferrer noopener" target="_blank">
    {children}
  </StyledLink>
);

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.terracotta};

  &:hover {
    color: ${(props) => props.theme.color.terracotta};
  }
`;

export default ExternalLink;
