import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "@components/Layout";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import Header from "@sections/Header";
import Gifts from "@sections/Gifts";
import Location from "@sections/Location";
import Info from "@sections/Info";
import TheDay from "@sections/TheDay";
import Sheffield from "@sections/Sheffield";

const IndexPage = () => {
  return (
    <Layout>
      <Navbar />
      <Header />
      <TheDay />
      <Location />
      <Sheffield />
      <Gifts />
      <Info />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
