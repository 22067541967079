import React from "react";

import { Section, Container } from "@components/global";

import FaqItem from "@components/FaqItem";
import ExternalLink from "@components/ExternalLink";
import styled from "styled-components";

const Sections = [
  {
    title: "Covid-19",
    content: () => (
      <>
        <p>
          The current government roadmap sets out plans to hopefully ease all
          restrictions by the 21st June and we have decided to plan as such.
        </p>
        <p>
          We would ask you to please rsvp as normal and we will keep all guests
          updated if the situation changes. We appreciate that this is
          frustrating and inconvenient and would advise guests to book
          accommodation with a good cancellation policy where possible.
        </p>
        <p>
          Fingers crossed everything will go to plan and we will party like its
          pre-2020!
        </p>
      </>
    ),
  },
  {
    title: "Ceremony Venue",
    content: () => (
      <>
        <p>
          The ceremony will be held outdoors and unfortunately we cannot
          guarantee the weather. We recommend you are prepared for all weather
          conditions.
        </p>

        <p>
          For the ceremony there will be limited seating, if you require a seat
          for the ceremony please let us know. Toilets are available and located
          in Weston Park museum in the grounds of the park.
        </p>

        <p>
          Due to the nature of our venue the use of paper or rice confetti is
          not permitted. Please feel free to bring fresh or dried petal
          confetti.
        </p>
      </>
    ),
  },
  {
    title: "Accommodation",
    content: () => (
      <>
        <p>
          The bride and her family will be staying at the{" "}
          <ExternalLink href="https://all.accor.com/hotel/6628/index.en.shtml">
            Mercure Sheffield St Paul's Hotel & Spa
          </ExternalLink>{" "}
          which is a 9 min walk from our reception venue Sidney + Matilda.
        </p>

        <p>
          There are also a number of other hotels within a short walking
          distance of S+M such as <ExternalLink>Jury's Inn</ExternalLink> (4min
          walk),{" "}
          <ExternalLink href="https://all.accor.com/hotel/1348/index.en.shtml">
            Novotel
          </ExternalLink>{" "}
          (8min walk) &{" "}
          <ExternalLink href="https://www.premierinn.com/gb/en/hotels/england/south-yorkshire/sheffield/sheffield-city-centre-st-marys-gate.html">
            Premier Inn
          </ExternalLink>{" "}
          (8min walk).
        </p>
      </>
    ),
  },
];

const Info = () => (
  <Section id="important info">
    <Container>
      <Head>Important Information</Head>
      <div>
        {Sections.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Info;

const Head = styled.h2`
  color: ${(props) => props.theme.color.navy};
  margin: 40px 0px;
`;
