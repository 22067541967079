import React from "react";
import styled from "styled-components";

import { Section, Container } from "@components/global";
import ExternalLink from "../components/ExternalLink";

const Gifts = () => (
  <Section id="gifts" accent="primary">
    <Container style={{ position: "relative" }}>
      <h2 style={{ marginTop: 40, marginBottom: 30 }}>Gifts</h2>
      <Para>
        We are so looking forward to spending our special day with you, and this
        is the best gift you can give us.
      </Para>
      <Para>
        Please do not feel obliged to get us anything. However, if you would
        like to make a contribution to our honeymoon fund we would be very
        grateful.
      </Para>
      <T>
        <ExternalLink href="https://prezola.com/wishlists/10232332/">
          <GiftButton>Honeymoon Fund</GiftButton>
        </ExternalLink>
      </T>
    </Container>
  </Section>
);

const T = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Para = styled.p`
  margin-top: 16px;
  color: ${(props) => props.theme.color.pink};
  font-family: ${(props) => props.theme.font.secondary};
  margin-bottom: 40px;
  font-size: 20px;
`;

const GiftButton = styled.button`
  border: 3px ${(props) => props.theme.color.pink} solid;
  font-family: ${(props) => props.theme.font.primary};
  outline: none;
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.color.pink};
  border-radius: 10px;
  font-size: 24px;
  font-weight: 300;
  width: 230px;
  margin-top: 50px;
`;

export default Gifts;
